import { useTranslation } from "react-i18next";
import UrlParametersKey from "utils/UrlParametersKey";

const usePartners = () => {
  const { t } = useTranslation();

  const entry = sessionStorage.getItem(UrlParametersKey.ENTRY);

  const PartnersEnum = {
    LPFRCP: "lpfrcp",
    LPFRDPS: "lpfrdps",
    PDIFF: "pdiff",
    PFID: "pfid",
    CARTEPRO: "cartepro",
  };

  const partners = [
    {
      id: PartnersEnum.LPFRCP,
      home: {
        title: t("partners:lpfrcp.home.title"),
        content: t("partners:lpfrcp.home.content"),
      },
      certificationSuccessManual: {
        content: t("partners:lpfrcp.certificationSuccessManual.content"),
        cta: t("partners:lpfrcp.certificationSuccessManual.cta"),
      },
      certificationSuccessAutomatic: {
        content: t("partners:lpfrcp.certificationSuccessAutomatic.content"),
        cta: t("partners:lpfrcp.certificationSuccessAutomatic.cta"),
      },
    },
    {
      id: PartnersEnum.LPFRDPS,
      home: {
        logo: "/lpfrdps.png",
        title: t("partners:lpfrdps.home.title"),
        content: t("partners:lpfrdps.home.content"),
      },
      certificationSuccessManual: {
        content: t("partners:lpfrdps.certificationSuccessManual.content"),
        cta: t("partners:lpfrdps.certificationSuccessManual.cta"),
      },
      certificationSuccessAutomatic: {
        content: t("partners:lpfrdps.certificationSuccessAutomatic.content"),
        cta: t("partners:lpfrdps.certificationSuccessAutomatic.cta"),
      },
    },
    {
      id: PartnersEnum.PDIFF,
      home: {
        title: t("partners:pdiff.home.title"),
        content: t("partners:pdiff.home.content"),
      },
      certificationSuccessManual: {
        content: t("partners:pdiff.certificationSuccessManual.content"),
        cta: t("partners:pdiff.certificationSuccessManual.cta"),
      },
      certificationSuccessAutomatic: {
        content: t("partners:pdiff.certificationSuccessAutomatic.content"),
        cta: t("partners:pdiff.certificationSuccessAutomatic.cta"),
      },
    },
    {
      id: PartnersEnum.PFID,
      home: {
        logo: "/pfid.png",
        title: t("partners:pfid.home.title"),
        content: t("partners:pfid.home.content"),
      },
      certificationSuccessManual: {
        content: t("partners:pfid.certificationSuccessManual.content"),
        cta: t("partners:pfid.certificationSuccessManual.cta"),
      },
      certificationSuccessAutomatic: {
        content: t("partners:pfid.certificationSuccessAutomatic.content"),
        cta: t("partners:pfid.certificationSuccessAutomatic.cta"),
      },
    },
    {
      id: PartnersEnum.CARTEPRO,
      home: {
        logo: "/cartepro.png",
        title: t("partners:cartepro.home.title"),
        content: t("partners:cartepro.home.content"),
      },
      certificationSuccessManual: {
        content: t("partners:cartepro.certificationSuccessManual.content"),
        cta: t("partners:cartepro.certificationSuccessManual.cta"),
      },
      certificationSuccessAutomatic: {
        content: t("partners:cartepro.certificationSuccessAutomatic.content"),
        cta: t("partners:cartepro.certificationSuccessAutomatic.cta"),
      },
    },
  ];

  const hasPartnerOffer = () =>
    partners.find((p) => p.id === entry) !== undefined;

  const getPartnerHomeLogo = () =>
    partners.find((p) => p.id === entry)?.home?.logo || "/certification.png";

  const getPartnerHomeTitle = () =>
    partners.find((p) => p.id === entry)?.home?.title ||
    t("partners:default.home.title");

  const getPartnerHomeContent = () =>
    partners.find((p) => p.id === entry)?.home?.content || "";

  const getPartnerCertificationSuccessManualContent = () =>
    partners.find((p) => p.id === entry)?.certificationSuccessManual?.content ||
    "";

  const getPartnerCertificationSuccessManualCta = () =>
    partners.find((p) => p.id === entry)?.certificationSuccessManual?.cta || "";

  const getPartnerCertificationSuccessAutomaticContent = () =>
    partners.find((p) => p.id === entry)?.certificationSuccessAutomatic
      ?.content || "";

  const getPartnerCertificationSuccessAutomaticCta = () =>
    partners.find((p) => p.id === entry)?.certificationSuccessAutomatic?.cta ||
    "";

  return {
    hasPartnerOffer,
    entry,
    getPartnerHomeLogo,
    getPartnerHomeTitle,
    getPartnerHomeContent,
    getPartnerCertificationSuccessManualContent,
    getPartnerCertificationSuccessManualCta,
    getPartnerCertificationSuccessAutomaticContent,
    getPartnerCertificationSuccessAutomaticCta,
  };
};

export default usePartners;
