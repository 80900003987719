import React from "react";
import DisconnectedHome from "pages/DisconnectedHome";
import ServiceUnavailable from "pages/ServiceUnavailable";
import Logout from "pages/Logout";
import withTracker from "components/withTracker";
import { getTags } from "utils/TagUtil";

const HOME_DISCONNECTED = {
  path: "/",
  Component: withTracker((props) => <DisconnectedHome {...props} />, {
    tcVars: getTags({
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.5-non_connecte",
      step: "0.5",
    }),
  }),
};
const SERVICE_UNAVAILABLE = {
  path: "/service-indisponible",
  Component: withTracker((props) => <ServiceUnavailable {...props} />, {
    tcVars: getTags({
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.4-certification_indisponible",
      step: "0.4",
    }),
  }),
};
const LOGOUT = {
  path: "/logout",
  Component: Logout,
};

export { HOME_DISCONNECTED, SERVICE_UNAVAILABLE, LOGOUT };
