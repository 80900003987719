import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import ArrowLinkIcon from "icons/arrowLink";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@material-ui/core";
import { currentLanguage, langueList } from "config/i18n";
import ExpandMoreIcon from "icons/expandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette["background-light"],
    color: theme.palette["01-body-background-charte-gris-6-tex"],
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "#262626",
      color: "#fff",
    },
  },
  link: {
    flex: "auto",
    textAlign: "center",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "15px",
    padding: "13px 0",
    color: "inherit",
    "&:not(:last-child):after": {
      content: '"|"',
      float: "right",
      color: theme.palette.divider,
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "40px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
      flex: "none",
      paddingLeft: "40px",
      "&:last-child": {
        paddingRight: "40px",
      },
    },
  },
  arrowLink: {
    width: "8px",
    height: "8px",
    marginLeft: theme.spacing(1),
    fill: theme.palette["01-body-background-charte-gris-6-tex"],
    [theme.breakpoints.up("lg")]: {
      fill: "#fff",
    },
  },
  accInfo: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    fontSize: "10px",
    lineHeight: "1.5",
    "&& > a": {
      color: theme.palette["01-body-background-charte-gris-6-tex"],
    },
    backgroundColor: theme.palette["background-light"],
    color: theme.palette["01-body-background-charte-gris-6-tex"],
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
      backgroundColor: "#262626",
      color: "#fff",
      "&& > a": {
        color: "#fff",
      },
    },
  },
  openChooseLanguageMenuPaper: {
    background: "#555",
    color: "#fff",
    "&& .Mui-selected": {
      background: "#717171",
    },
  },
}));

const Footer = ({ onRefChange }) => {
  const classes = useStyles();

  const { i18n, t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const { pathname } = location;

  const [anchorChooseLanguage, setAnchorChooseLanguage] = useState(null);
  const [language, setLanguage] = useState(currentLanguage);

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    setAnchorChooseLanguage(null);
  };

  const isAnchorChooseLanguageOpen = Boolean(anchorChooseLanguage);

  const {
    REACT_APP_CGU_URL,
    REACT_APP_FOOTER_ACCESSIBILITY_URL,
    REACT_APP_FOOTER_HELP_URL,
  } = process.env;

  const links = [
    {
      href: REACT_APP_CGU_URL,
      text: t("footer:link.cgu"),
      mobileText: "CGU",
    },
    {
      href: REACT_APP_FOOTER_ACCESSIBILITY_URL,
      text: t("footer:link.accessibility"),
    },
    {
      href: REACT_APP_FOOTER_HELP_URL,
      text: t("footer:link.help"),
    },
  ];

  return (
    <footer ref={onRefChange} role="contentinfo">
      <Menu
        id="open-choose-language-menu"
        classes={{ paper: classes.openChooseLanguageMenuPaper }}
        MenuListProps={{
          "aria-labelledby": "open-choose-language",
        }}
        anchorEl={anchorChooseLanguage}
        getContentAnchorEl={null}
        open={isAnchorChooseLanguageOpen}
        onClose={() => setAnchorChooseLanguage(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            marginTop: theme.spacing(-1.5),
          },
        }}
      >
        {Object.keys(langueList).map((languageKey) => {
          const { name } = langueList[languageKey];
          return (
            <MenuItem
              key={name}
              onClick={() => changeLanguage(languageKey)}
              selected={languageKey === language}
              aria-label={name}
              lang={languageKey}
            >
              {name}
            </MenuItem>
          );
        })}
      </Menu>
      {pathname === "/" && (
        <Typography className={classes.accInfo}>
          {t("footer:infos.legalMentions")}{" "}
          <a href="https://www.laposte.fr/professionnel/mentions-informations-a-l-inscription">
            https://www.laposte.fr/professionnel/mentions-informations-a-l-inscription
          </a>
          .
        </Typography>
      )}
      <Grid container className={classes.root}>
        <Grid
          id="open-choose-language"
          item
          className={classes.link}
          role="button"
          tabIndex={0}
          aria-controls={
            isAnchorChooseLanguageOpen ? "open-choose-language-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={isAnchorChooseLanguageOpen ? "true" : undefined}
          aria-label={t("footer:menuLanguage.chooseLanguage.ariaLabel")}
          onClick={(e) => {
            setAnchorChooseLanguage(e.currentTarget);
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              setAnchorChooseLanguage(e.currentTarget);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <span>
            <>
              {isMobile
                ? langueList[language].mobileName
                : langueList[language].name}
            </>
            <ExpandMoreIcon
              style={{
                verticalAlign: "middle",
                marginLeft: theme.spacing(0.625),
              }}
            />
          </span>
        </Grid>
        {links.map((link) => {
          const { href, text, mobileText } = link;
          return (
            <Grid
              key={href}
              item
              className={classes.link}
              component={Link}
              target="_blank"
              href={href}
              underline="none"
              color="inherit"
              aria-label={`${text}, ouverture vers un nouvel onglet`}
            >
              <span>{mobileText && isMobile ? mobileText : text}</span>
              <ArrowLinkIcon
                className={classes.arrowLink}
                viewBox="0 0 10 10"
              />
            </Grid>
          );
        })}
      </Grid>
    </footer>
  );
};

Footer.propTypes = {
  onRefChange: PropTypes.func.isRequired,
};

export default Footer;
