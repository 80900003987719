import UrlParametersKey from "utils/UrlParametersKey";
import TokenService from "services/TokenService";
import {
  getAutomaticStatus,
  getManualSwitchStatus,
} from "./AutomaticStatusUtil";

export const getLogoutUrlWithCallback = (callbackUrl) => {
  let redirectUrl = callbackUrl;
  if (!callbackUrl) {
    const urlb = sessionStorage.getItem(UrlParametersKey.URL_BACK);
    const urlba = sessionStorage.getItem(UrlParametersKey.URL_BACK_AUTOMATIC);
    const urlbm = sessionStorage.getItem(UrlParametersKey.URL_BACK_MANUAL);
    const entry = sessionStorage.getItem(UrlParametersKey.ENTRY);

    if (!urlb && !urlba && !urlbm && !entry) {
      redirectUrl = `${process.env.REACT_APP_CLIENT_AUTH_URL}?callbackURL=${window.location.origin}`;
    } else {
      let callbackURLParams = `${UrlParametersKey.ENTRY}=${entry}`;

      if (urlb) {
        callbackURLParams = `${callbackURLParams}&${UrlParametersKey.URL_BACK}=${urlb}`;
      }

      if (urlba) {
        callbackURLParams = `${callbackURLParams}&${UrlParametersKey.URL_BACK_AUTOMATIC}=${urlba}`;
      }

      if (urlbm) {
        callbackURLParams = `${callbackURLParams}&${UrlParametersKey.URL_BACK_MANUAL}=${urlbm}`;
      }

      redirectUrl = `${process.env.REACT_APP_CLIENT_AUTH_URL}?callbackURL=${window.location.origin}?${callbackURLParams}`;
    }
  }
  return `${process.env.REACT_APP_CLIENT_LOGOUT_URL}?source=switch&callbackUrl=${redirectUrl}`;
};

export const getReturnUrl = () => {
  const urlb = sessionStorage.getItem(UrlParametersKey.URL_BACK);
  const urlba = sessionStorage.getItem(UrlParametersKey.URL_BACK_AUTOMATIC);
  const urlbm = sessionStorage.getItem(UrlParametersKey.URL_BACK_MANUAL);

  if (getAutomaticStatus() && urlba) {
    return urlba;
  }
  if (getManualSwitchStatus() && urlbm) {
    return urlbm;
  }
  return urlb || process.env.REACT_APP_ECP_DASHBOARD;
};

export const getLogoutUrl = () => {
  const idToken = TokenService.getIdToken();
  return `${process.env.REACT_APP_LOGOUT_URL}?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_CERTIF_URL}/logout`;
};

export default {
  getLogoutUrlWithCallback,
};
