import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import React from "react";
import Button from "components/Button";
import { getReturnUrl } from "utils/UrlUtil";
import Step from "components/Layout/Step";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  image: {
    width: 100,
    height: 100,
  },
}));

const CertifiedAccount = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const serviceName = useServiceName();

  const title = t("certifiedAccount:title");

  return (
    <Step progress={100}>
      <Illustration>
        <img
          className={classes.image}
          src="/certification.png"
          alt={title}
          aria-hidden
          title={title}
        />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" align="center">
          {t("certifiedAccount:content")}
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100% " }}>
          <Link href={getReturnUrl()} underline="none">
            <Button>
              {t("common:goTo")} {serviceName}
            </Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
};

export default CertifiedAccount;
