import axios from "axios";
import TokenService from "./TokenService";

const CERTIFICATION_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/users/me/certifications`;
const PROCURATION_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/users/me/procurations`;

let procurationStatus = null;

export default class CertificationService {
  static create = (documentIds, type) =>
    axios.post(`${CERTIFICATION_PATH}?idToken=${TokenService.getIdToken()}`, {
      documentIds,
      type,
    });

  static get = (certificationId) =>
    axios.get(`${CERTIFICATION_PATH}/${certificationId}`);

  static getProcuration = (idToken) =>
    axios.get(`${PROCURATION_PATH}?idToken=${idToken}`);

  static setProcurationStatus = (newProcurationStatus) => {
    procurationStatus = newProcurationStatus;
  };

  static getProcurationStatus = () => procurationStatus;
}
