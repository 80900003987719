import axios from "axios";
import CompanyDirectorStatus from "utils/CompanyDirectorStatus";
import UserService from "services/UserService";
import TokenService from "./TokenService";

let companyPromise = null;
let isCompanyFetchingForChoiceOfLegalRepresentative = false;
let choiceOfLegalRepresentative = "";

export default class CompanyService {
  static refreshCompany = () => {
    const hasSiretPromise = UserService.hasSiretNumber();
    companyPromise = hasSiretPromise
      .then((hasSiret) => {
        if (hasSiret) {
          return axios
            .get(
              `${
                process.env.REACT_APP_CRL_BACK_URL
              }/companies/mine?idToken=${TokenService.getIdToken()}`,
            )
            .then((response) => response.data)
            .catch((error) => error.response);
        }
        return null;
      })
      .catch((error) => error.response);
    return companyPromise;
  };

  static get = (forceRefresh = false) => {
    if (companyPromise instanceof Promise && !forceRefresh) {
      return companyPromise;
    }
    return CompanyService.refreshCompany();
  };

  static getStatus = async () => {
    const companyDetails = await CompanyService.get();

    if (!companyDetails) {
      return CompanyDirectorStatus.ERROR;
    }

    if (companyDetails.status && companyDetails.status !== 200) {
      const { status } = companyDetails;

      // Si le siret ne correspond à aucune entreprise chez societe.com ou qu'il ne possède pas de siret
      if (status === 404 || status === 400) {
        return CompanyDirectorStatus.ERROR;
      }
    }

    // Si aucune personne physique n'est déclarée comme dirigeant
    if (companyDetails.nbPhysicalDirectors === 0) {
      return CompanyDirectorStatus.NO_PHYSICAL_DIRECTOR;
    }

    // Si la personne est déclarée comme dirigeant
    if (companyDetails.directorMatching !== undefined) {
      return CompanyDirectorStatus.USER_IS_DIRECTOR;
    }

    return CompanyDirectorStatus.USER_IS_NOT_DIRECTOR;
  };

  static getIsCompanyFetchingForChoiceOfLegalRepresentative = () =>
    isCompanyFetchingForChoiceOfLegalRepresentative;

  static setIsCompanyFetchingForChoiceOfLegalRepresentative = (
    newIsCompanyFetchingForChoiceOfLegalRepresentative,
  ) => {
    isCompanyFetchingForChoiceOfLegalRepresentative =
      newIsCompanyFetchingForChoiceOfLegalRepresentative;
  };

  static getChoiceOfLegalRepresentative = () => choiceOfLegalRepresentative;

  static setChoiceOfLegalRepresentative = (newChoiceOfLegalRepresentative) => {
    choiceOfLegalRepresentative = newChoiceOfLegalRepresentative;
  };

  static hasActiveSiret = async () => {
    try {
      const legalEntityParams = await axios.get(
        `${
          process.env.REACT_APP_CRL_BACK_URL
        }/companies/legal-entity-params?idToken=${TokenService.getIdToken()}`,
      );
      return legalEntityParams?.data?.siretStatus !== "INACTIVE";
    } catch (e) {
      return true;
    }
  };
}
