import axios from "axios";
import { isValidAddress } from "utils/AddressUtil";
import User from "models/User";
import UrlParametersKey from "utils/UrlParametersKey";

const invalidCompaniesName =
  (process.env.REACT_APP_INVALID_COMPANIES_NAME &&
    process.env.REACT_APP_INVALID_COMPANIES_NAME.split(",")) ||
  [];

let userPromise = null;
let userId = "";

let cguAccepted = false;

export default class UserService {
  static refreshUser = () => {
    userPromise = axios
      .get(`${process.env.REACT_APP_CRL_BACK_URL}/users/me`, {
        params: {
          origin: sessionStorage.getItem(UrlParametersKey.ENTRY) || "direct",
        },
      })
      .then((response) => {
        const user = new User(response.data);
        userId = user.userId;
        return user;
      });
    return userPromise;
  };

  static get = () => {
    if (userPromise instanceof Promise) {
      return userPromise;
    }
    return UserService.refreshUser();
  };

  static getUserId = () => userId;

  static updateInfo = ({ familyName, givenName, gender }) =>
    axios.put(`${process.env.REACT_APP_CRL_BACK_URL}/users/me`, {
      familyName,
      givenName,
      gender,
    });

  static validateCompanyInfo = () =>
    axios.put(`${process.env.REACT_APP_CRL_BACK_URL}/users/me/company`, {});

  static hasCoclico = () =>
    UserService.get()
      .then((user) => Boolean(user && user.company))
      .catch(() => false);

  static hasValidCoclico = () =>
    UserService.get()
      .then((user) =>
        Boolean(user && user.company && user.company.active === "1"),
      )
      .catch(() => false);

  static hasCertification = () =>
    UserService.get()
      .then((user) => Boolean(user && user.status === 1))
      .catch(() => false);

  static hasCertificationInProgress = () =>
    UserService.get()
      .then((user) => Boolean(user && user.status === 9))
      .catch(() => false);

  static isNonDiffusibleCompany = () =>
    UserService.get()
      .then((user) =>
        Boolean(
          user?.company?.name
            ? invalidCompaniesName.includes(user.company.name)
            : false,
        ),
      )
      .catch(() => false);

  static hasCompanyInfo = () =>
    UserService.get()
      .then((user) =>
        Boolean(user?.company?.name && isValidAddress(user.company.address)),
      )
      .catch(() => false);

  static hasSiretNumber = () =>
    UserService.get()
      .then((user) => Boolean(user && user.company && user.company.siret))
      .catch(() => false);

  static setAcceptCgu = (value) => {
    cguAccepted = value;
  };

  static hasAcceptedCgu = () => cguAccepted;
}
