import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, useLocation, useHistory } from "react-router-dom";
import SettingsService from "services/SettingsService";
import { formatSettings } from "utils/SettingsUtil";
import UrlParametersKey from "utils/UrlParametersKey";
import randomstring from "randomstring";
import Loader from "components/Loader";
import TokenService from "services/TokenService";
import UrlBackService from "services/UrlBackService";
import AuthCCURedirect from "./AuthCCURedirect";
import SettingsContext from "../Settings/SettingsContext";

const sessionStorageState = "_stateStorage";

const AuthCCUProvider = (props) => {
  const { children, onError } = props;

  const [settings, setSettings] = useState({});
  const [isValidUrlBack, setIsValidUrlBack] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    const checkUrlBack = async () => {
      const urlb = new URLSearchParams(search).get(UrlParametersKey.URL_BACK);
      const urlba = new URLSearchParams(search).get(
        UrlParametersKey.URL_BACK_AUTOMATIC,
      );
      const urlbm = new URLSearchParams(search).get(
        UrlParametersKey.URL_BACK_MANUAL,
      );

      if (urlb || urlba || urlbm) {
        try {
          sessionStorage.removeItem(UrlParametersKey.URL_BACK);
          sessionStorage.removeItem(UrlParametersKey.URL_BACK_AUTOMATIC);
          sessionStorage.removeItem(UrlParametersKey.URL_BACK_MANUAL);

          const urlsBack = [];
          if (urlb) {
            urlsBack.push(urlb);
          }
          if (urlba) {
            urlsBack.push(urlba);
          }
          if (urlbm) {
            urlsBack.push(urlbm);
          }
          const { data } = await UrlBackService.checkUrlsBack(urlsBack);
          if (data) {
            if (urlb) {
              sessionStorage.setItem(UrlParametersKey.URL_BACK, urlb);
            }
            if (urlba) {
              sessionStorage.setItem(
                UrlParametersKey.URL_BACK_AUTOMATIC,
                urlba,
              );
            }
            if (urlbm) {
              sessionStorage.setItem(UrlParametersKey.URL_BACK_MANUAL, urlbm);
            }
            setIsValidUrlBack(true);
          } else {
            history.push("/service-indisponible");
          }
        } catch (_) {
          history.push("/service-indisponible");
        }
      } else {
        setIsValidUrlBack(true);
      }
    };

    checkUrlBack();
  }, [history, search]);

  const sn = new URLSearchParams(search).get(UrlParametersKey.SERVICE_NAME);
  if (sn) {
    sessionStorage.setItem(UrlParametersKey.SERVICE_NAME, sn);
  }

  const handleAuthSuccess = useCallback(
    async (auth) => {
      TokenService.setJwToken(auth);

      SettingsService.get()
        .then((settingsResponse) => {
          setSettings(formatSettings(settingsResponse));
          history.push("/");
        })

        .catch(() => {
          history.push("/service-indisponible");
        });
    },
    [history],
  );

  const redirectBase = `${window.location.origin}${process.env.REACT_APP_BASENAME}`;
  const redirectPath = process.env.REACT_APP_CCU_REDIRECT_PATH;
  if (
    isValidUrlBack &&
    !TokenService.getJwToken() &&
    pathname !== redirectPath
  ) {
    const state = randomstring.generate();
    sessionStorage.setItem(sessionStorageState, state);
    window.location.href = `${process.env.REACT_APP_CCU_URL}?response_type=code&client_id=${process.env.REACT_APP_CCU_CLIENT_ID}&scope=${process.env.REACT_APP_CCU_SCOPE}&state=${state}&redirect_uri=${redirectBase}${redirectPath}`;

    return null;
  }

  return (
    <SettingsContext.Provider value={settings}>
      <Route path={redirectPath} exact>
        <AuthCCURedirect onSuccess={handleAuthSuccess} onError={onError} />
      </Route>
      <Loader isLoading={!TokenService.getJwToken()}>
        {Boolean(TokenService.getJwToken()) && children}
      </Loader>
    </SettingsContext.Provider>
  );
};

AuthCCUProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AuthCCUProvider;
