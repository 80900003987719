import React, { useCallback, useState } from "react";
import { useGlobalStyles } from "theme";
import { Route, Switch, useLocation } from "react-router-dom";
import AuthCCUProvider from "components/AuthCCU/AuthCCUProvider";
import Link from "components/Link";
import Layout from "Layout";
import StyleGuide from "StyleGuide";
import Routes from "Routes";
import { HOME_DISCONNECTED, SERVICE_UNAVAILABLE, LOGOUT } from "PublicRoutes";
import UrlParametersKey from "utils/UrlParametersKey";

const Router = () => {
  const location = useLocation();
  const { search } = location;

  const [disconnected, setDisconnected] = useState(false);

  const handleAuthError = useCallback(() => {
    setDisconnected(true);
  }, []);

  useGlobalStyles();

  const entry = new URLSearchParams(search).get(UrlParametersKey.ENTRY);
  if (entry) {
    sessionStorage.setItem(UrlParametersKey.ENTRY, entry);
  }

  return (
    <Layout>
      {process.env.NODE_ENV === "development" && (
        <Link
          to="/charte-graphique"
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            backgroundColor: "#fff",
            padding: "4px",
          }}
        >
          Charte graphique
        </Link>
      )}
      <Switch>
        {process.env.NODE_ENV === "development" && (
          <Route path="/charte-graphique" component={StyleGuide} exact />
        )}
        <Route
          exact
          path={SERVICE_UNAVAILABLE.path}
          component={SERVICE_UNAVAILABLE.Component}
        />
        <Route exact path={LOGOUT.path} component={LOGOUT.Component} />
        {!disconnected && (
          <AuthCCUProvider
            onError={handleAuthError}
            data-testid="connectedHome"
          >
            <Routes />
          </AuthCCUProvider>
        )}
        {disconnected && (
          <Route
            exact
            path={HOME_DISCONNECTED.path}
            component={HOME_DISCONNECTED.Component}
            data-testid="disconnectedHome"
          />
        )}
      </Switch>
    </Layout>
  );
};

export default Router;
