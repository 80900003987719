import {
  createMuiTheme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

const defaultTheme = createMuiTheme();

const colors = {
  "brand-pro-01-purple-01-default": "#92004d",
  cerulean: "#0879d1",
  "f-57324-orange": "#f57324",
  blue: "#1787e0",
  "body-black-333333": "#333333",
  red: "#ff6660",
  "icon-grey": "#706f6f",
  "background-dark": "#3c3c3c",
  "brand-02-blue-01-default": "#20458f",
  "yellow-la-poste": "#ffc928",
  "status-disabled-pro": "rgba(146, 0, 77, 0.15)",
  "background-light": "#ffffff",
  "status-disabled-pro-2": "rgba(146, 0, 77, 0.19)",
  "status-error": "#990705",
  "background-dark-4-c-4-c-4-c": "#595959",
  "status-icon-error": "#CD3939",
  "status-icon-information": "#3561c6",
  "status-icon-warning": "#ed6508",
  "status-background-warning-backgro": "#ffe4cc",
  "status-icon-success": "#159d2d",
  "status-background-success-backgro": "#daf1e4",
  "status-background-error-backgroun": "#f3d5d5",
  "01-body-background-charte-gris-6-tex": "#555555",
  "status-background-information-bac": "#edf3ff",
  "sketch-measure-spacing": "#50e3c2",
  "sketch-measure-property": "#f5a623",
  "sketch-measure-size": "#ff5500",
  "-body-background-charte-gris-5": "#949494",
  "body-background-charte-gris-4": "#d2d2d1",
  "ranger-dark-2-c-2-c-2-c": "#2c2c2c",
  "status-background-information-b-2": "#d6e4ff",
  "neutral-neutral-3": "#e1e1e1",
  "status-information": "#3c6cc8",
  "-body-background-charte-gris-2": "#f7f7f7",
  "neutral-neutral-6": "#717171",
  "text-erreur": "#cd3939",
};

const primary = {
  light: colors["brand-pro-01-purple-01-default"],
  main: colors["brand-pro-01-purple-01-default"],
  dark: colors["brand-pro-01-purple-01-default"],
};

const secondary = {
  light: colors.cerulean,
  main: colors.cerulean,
  dark: colors.cerulean,
};

const error = {
  light: colors["text-erreur"],
  main: colors["text-erreur"],
  dark: colors["text-erreur"],
};

const breakpoints = {
  values: {
    xs: 320,
    sm: 414,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
};

const theme = createMuiTheme({
  breakpoints,
  palette: {
    primary,
    secondary,
    error,
    divider: colors["body-background-charte-gris-4"],
    ...colors,
  },
  typography: {
    fontFamily: '"Roboto"',
    h1: {
      color: colors["background-dark"],
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      letterSpacing: "-0.2px",
      [defaultTheme.breakpoints.up(breakpoints.values.sm)]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
      [defaultTheme.breakpoints.up(breakpoints.values.md)]: {
        fontSize: "24px",
        lineHeight: "36px",
      },
      [defaultTheme.breakpoints.up(breakpoints.values.lg)]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      [defaultTheme.breakpoints.up(breakpoints.values.xl)]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    h2: {
      color: colors["background-dark"],
      fontSize: "18px",
      lineHeight: "27px",
      fontWeight: 700,
      [defaultTheme.breakpoints.up(breakpoints.values.md)]: {
        fontSize: "24px",
        lineHeight: "36px",
      },
      [defaultTheme.breakpoints.up(breakpoints.values.lg)]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
      [defaultTheme.breakpoints.up(breakpoints.values.xl)]: {
        fontSize: "24px",
        lineHeight: "36px",
      },
    },
    h3: {
      color: colors["background-dark"],
      fontSize: "16px",
      lineHeight: "24px",
    },
    body1: {
      fontSize: "14px",
      lineHeight: "21px",
      [defaultTheme.breakpoints.up(breakpoints.values.md)]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      [defaultTheme.breakpoints.up(breakpoints.values.lg)]: {
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
    body2: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "21px",
      color: colors["brand-02-blue-01-default"],
      fontFamily: "Roboto",
      fontWeight: "500",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "#202124",
      },
    },
    MuiButton: {
      root: {
        height: "48px",
        borderRadius: "10px",
        fontFamily: "Roboto",
        fontWeight: 700,
        padding: "12px 20px",
        color: colors["background-light"],
        [defaultTheme.breakpoints.between(
          breakpoints.values.md,
          breakpoints.values.lg,
        )]: {
          height: "60px",
          padding: "16.5px 20px",
        },
      },
      label: {
        textTransform: "none",
        fontSize: "16px",
        lineHeight: 1.5,
        [defaultTheme.breakpoints.between(
          breakpoints.values.md,
          breakpoints.values.lg,
        )]: {
          fontSize: "18px",
        },
      },
      containedPrimary: {
        border: `1px solid ${primary.main}`,
        "&$disabled": {
          background: colors["brand-pro-01-purple-01-default"],
          color: colors["background-light"],
          opacity: "0.32",
        },
        "&:hover": {
          backgroundColor: "#650035",
        },
      },
    },
    MuiInputBase: {
      input: {
        height: "22px",
        lineHeight: "22px",
      },
      root: {
        fontSize: "16px",
        color: colors["background-dark"],
        "&$disabled": {
          color: colors["background-dark"],
          WebkitTextFillColor: colors["background-dark"],
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors["01-body-background-charte-gris-6-tex"],
        "&$disabled": {
          color: colors["01-body-background-charte-gris-6-tex"],
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: colors["-body-background-charte-gris-5"],
      },
      input: {
        padding: "28px 14px 12px",
      },
      multiline: {
        padding: "28px 14px 12px",
      },
      root: {
        borderRadius: "8px",
        "&$disabled": {
          "& $notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 22px) scale(1)",
        "&$shrink": {
          transform: "translate(14px, 12px) scale(0.85)",
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        top: 0,
      },
      legendLabelled: {
        display: "none",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1em",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&.Mui-checked": {
          color: colors["brand-02-blue-01-default"],
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: 700,
        color: colors.cerulean,
      },
    },
  },
});

export const useGlobalStyles = makeStyles(() =>
  createStyles({
    "@global": {
      ul: {
        listStylePosition: "initial",
        paddingInline: theme.spacing(3),
        "& li:not(:last-child)": {
          paddingBottom: theme.spacing(1.5),
        },
      },
    },
  }),
);

export default theme;
