import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Box, Grid, Typography } from "@material-ui/core";
import ClearIdIcon from "icons/id/clearId";
import VisibleIdIcon from "icons/id/visibleId";
import FullIdIcon from "icons/id/fullId";
import NoObstacleIdIcon from "icons/id/noObstacleId";
import FaceIdIcon from "icons/id/faceId";
import NoReflectIdIcon from "icons/id/noReflectId";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    padding: 14,
  },
  idIcon: {
    width: 74,
    height: 54,
  },
  idsRowContainer: {
    marginBottom: theme.spacing(2),
  },
  idLabel: {
    color: theme.palette["background-dark"],
    margin: `${theme.spacing(1)}px auto 0`,
    textAlign: "center",
    fontWeight: 500,
  },
}));

const IdErrorModalContent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.grayContainer}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.idsRowContainer}
        >
          <Box>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <VisibleIdIcon className={classes.idIcon} />
              <Typography variant="body2" className={classes.idLabel}>
                Bien éclairé
              </Typography>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <FullIdIcon className={classes.idIcon} />
              <Typography variant="body2" className={classes.idLabel}>
                En entier
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.idsRowContainer}
        >
          <Box>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <NoObstacleIdIcon className={classes.idIcon} />
              <Typography variant="body2" className={classes.idLabel}>
                Sans obstacle
              </Typography>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <ClearIdIcon className={classes.idIcon} />
              <Typography variant="body2" className={classes.idLabel}>
                Net
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.idsRowContainer}
        >
          <Box>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <FaceIdIcon className={classes.idIcon} />
              <Typography variant="body2" className={classes.idLabel}>
                De face
              </Typography>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <NoReflectIdIcon className={classes.idIcon} />
              <Typography variant="body2" className={classes.idLabel}>
                Sans reflet
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IdErrorModalContent;
