import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MiniCard from "components/Layout/MiniCard";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Step from "components/Layout/Step";
import MainContent from "components/Layout/MainContent";
import Actions from "components/Layout/Actions";
import { useHistory } from "react-router-dom";
import CompanyService from "services/CompanyService";
import Loader from "components/Loader";
import Button from "components/Button";
import MessageContext from "components/MessageContext";
import UserService from "services/UserService";
import TokenService from "services/TokenService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  cardLabel: {
    fontSize: "12px !important",
    color: `${theme.palette["01-body-background-charte-gris-6-tex"]} !important`,
    marginTop: "4px",
  },
}));

const ChoiceOfLegalRepresentative = () => {
  const classes = useStyles();

  const history = useHistory();
  const { t } = useTranslation();

  const { dispatchError } = useContext(MessageContext);

  const [choice, setChoice] = useState(
    CompanyService.getChoiceOfLegalRepresentative(),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [dirigeants, setDirigeants] = useState([]);

  useEffect(() => {
    const getIsCompanyFetchingForChoiceOfLegalRepresentative = async () => {
      try {
        const company = await CompanyService.get(
          !CompanyService.getIsCompanyFetchingForChoiceOfLegalRepresentative(),
        );

        if (!company?.status) {
          const newDirigeants = company?.dirigeants || [];
          CompanyService.setIsCompanyFetchingForChoiceOfLegalRepresentative(
            true,
          );
          if (newDirigeants.length > 0) {
            setDirigeants(newDirigeants);
            setIsLoading(false);
          } else {
            CompanyService.setChoiceOfLegalRepresentative("");
            history.push("/verification-client");
          }
        } else {
          history.push("/service-indisponible");
        }
      } catch (_) {
        history.push("/service-indisponible");
      }
    };

    getIsCompanyFetchingForChoiceOfLegalRepresentative();
  }, [history]);

  const choices = [
    {
      value: "DIRIGEANT_NOT_EXIST",
      title: t("choiceOfLegalRepresentative:diregeantNotExist.title"),
      subTitle: t("choiceOfLegalRepresentative:diregeantNotExist.subTitle"),
    },
    {
      value: "DELEGATE",
      title: t("choiceOfLegalRepresentative:delegate.title"),
      subTitle: t("choiceOfLegalRepresentative:delegate.subTitle"),
    },
  ];

  const handleClickValidate = async () => {
    if (choice && !choices.find((c) => c.value === choice)) {
      setIsLoading(true);

      try {
        const { gender, firstName, lastName } = dirigeants.find(
          (dirigeant) =>
            `${dirigeant.firstName} ${dirigeant.lastName}` === choice,
        );

        await UserService.updateInfo({
          gender: gender === "M" ? 1 : 2,
          givenName: firstName,
          familyName: lastName,
        });

        await UserService.refreshUser();

        await TokenService.refresh();

        CompanyService.setChoiceOfLegalRepresentative(choice);

        history.push("/verification-procuration");
      } catch (_) {
        setIsLoading(false);
        dispatchError(
          <>
            {t("common:error.internal")}
            <br />
            {t("common:error.notUpdated")}
          </>,
        );
      }
    } else if (choice === "DIRIGEANT_NOT_EXIST") {
      CompanyService.setChoiceOfLegalRepresentative(choice);
      history.push("/verification-client");
    } else {
      CompanyService.setChoiceOfLegalRepresentative(choice);
      history.push("/certification-rl-subdelegataire");
    }
  };

  return (
    <Loader isLoading={isLoading}>
      {!isLoading && (
        <Step
          title={t("choiceOfLegalRepresentative:title")}
          previousStepPath="/verification-societe"
          progress={30}
        >
          <MainContent>
            <FormControl fullWidth component="fieldset">
              <MiniCard type="tertiary" onClick={() => {}}>
                <Typography variant="body2" className={classes.cardLabel}>
                  {dirigeants?.length > 0
                    ? t("choiceOfLegalRepresentative:content.withDirigeants")
                    : t(
                        "choiceOfLegalRepresentative:content.withoutDirigeants",
                      )}
                </Typography>

                {dirigeants.map((dirigeant) => {
                  const { firstName, lastName } = dirigeant;
                  const key = `${firstName} ${lastName}`;

                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      onChange={() => setChoice(key)}
                      control={<Radio checked={choice === key} />}
                      style={{ width: "100%" }}
                      label={
                        <Typography variant="subtitle2">
                          {firstName} {lastName}
                        </Typography>
                      }
                    />
                  );
                })}
              </MiniCard>

              {choices.map((c) => {
                const { value, title, subTitle } = c;

                return (
                  <MiniCard
                    key={value}
                    type="tertiary"
                    onClick={() => setChoice(value)}
                  >
                    <FormControlLabel
                      value={value}
                      control={<Radio checked={choice === value} />}
                      label={
                        <>
                          <Typography variant="subtitle2">{title}</Typography>
                          <Typography
                            variant="body2"
                            className={classes.cardLabel}
                          >
                            {subTitle}
                          </Typography>
                        </>
                      }
                    />
                  </MiniCard>
                );
              })}
            </FormControl>
          </MainContent>
          <Actions>
            <Box mb={2} style={{ width: "100%" }}>
              <Button disabled={!choice} onClick={handleClickValidate}>
                {t("common:continue")}
              </Button>
            </Box>
          </Actions>
        </Step>
      )}
    </Loader>
  );
};

export default ChoiceOfLegalRepresentative;
