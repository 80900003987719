import React from "react";
import { Link } from "@material-ui/core";
import Button from "components/Button";
import ErrorPage from "pages/ErrorPage";
import { getReturnUrl } from "utils/UrlUtil";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const ErrorInactiveCoclico = () => {
  const serviceName = useServiceName();
  const { t } = useTranslation();

  return (
    <ErrorPage
      title={t("errorInactiveCoclico:title")}
      subtitle={t("errorInactiveCoclico:subTitle")}
      action={
        <Link style={{ width: "100%" }} href={getReturnUrl()} underline="none">
          <Button>Revenir à {serviceName}</Button>
        </Link>
      }
      imgSrc="/certification.png"
    />
  );
};

export default ErrorInactiveCoclico;
